.map-anchor {
  display: inline-block;
  margin-bottom: 1em;

  &::after {
    content: '\00a0→';
  }

  @include desktop {
    display: block;
    overflow: hidden;
    width: 0;
    height: 0;
    margin: 0 0 -1px;
    padding: 0;
    line-height: 0;
    font-size: 0;

    &::before, &::after { display: none; }
  }
}
