.edit-btn {
  position: absolute;
  bottom: 0;
  right: -$gutter * 2;
  transform: translateX(100%);

  @media print { display: none; }

  svg {
    @include easing(300ms, fill);
    fill: $lightgray;
  }

  &:hover svg {
    fill: $gray;
  }
}
