.pswp {
  z-index: 9;

  &__img {
    padding: $gutter;
    background-color: $white;
  }

  &__ui {
    -webkit-font-smoothing: unset;
  }

  &__counter {
    color: $black;
    font-family: 'Replica';
  }

  &__bg { background-color: $white; }
  &__caption {
    @include no-select;
    background-color: $white !important;
    opacity: 0.9;
    &__center {
      font-family: 'Tiempos';
      padding: $gutter;
      font-size: 1.4rem;
      line-height: 1.4em;
      text-align: center;
      color: $gray !important;
    }
  }

  &__button {
    body:not([data-night=true]) & { filter: invert(1); }
    &--arrow--left,
    &--arrow--right { margin: 0 $gutter / 2; }
  }

  &__top-bar {
    background: none !important;
    opacity: 1 !important;
    display: table !important;
  }
}

