.map-scale {
  @include no-select;

  z-index: 900;
  position: absolute;
  bottom: 1rem;
  left: 1rem;

  font-size: 1.4rem;
  border: 1px solid $black;
  background: $white;
  line-height: 2.8rem;

  &__bar {
    display: inline-block;
    padding: 0 1ch;

    &:not([data-label]) {
      display: none;
    }

    &::before {
      @include easing(300ms, width);

      content: '';
      display: inline-block;
      width: var(--scale-width, 256px);

      border-bottom: 1px solid $black;
      border-left: 1px solid $black;
      border-right: 1px solid $black;
      height: 0.25em;

      vertical-align: middle;
    }

    &::after {
      content: attr(data-label);
      padding-left: 1ch;
    }
  }

  &__value {
    display: none; // inline-block;
    border-left: 1px dashed $black;
    padding: 0 1ch;
  }
}
