.menu {
  @include invisible-scroll(y);
  @include easing(300ms, transform, true);
  z-index: 1;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  padding: $article__vertical-spacing $gutter;
  width: $menu__width--large;

  background-color: $white;
  border-right: 1px solid $black;

  @media print { display: none; }

  &:not(:hover) {
    transform: translateX($menu__width--small - $menu__width--large);
    & > ol  {
      transform: translateX($menu__width--large - $menu__width--small);
    }
    .menu-entry__title, ol ol ol .menu-entry { opacity: 0; }
  }

  // This dynamically pushes unopen entries to the top and bottom of the menu
  & > ol {
    @include easing(300ms, transform, true);
    line-height: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & > li:first-child { margin-top: 0; }

    & > li.is-open + li {
      margin-top: $gutter * 2;
    }

    & > .menu-entry.is-open,
    & > .menu-entry.is-active {
      flex: 1;
      &:not(:first-child) { padding-top: $gutter; }
    }
  }

  // Indent from depth 3
  ol ol ol > .menu-entry { padding-left: $gutter * 2; }

  // Close article tree when the article is not open
  .menu-entry:not(.is-open):not(.is-active) ol { display: none; }
}

// SEE layout.scss for close button implementation
.menu-close-button { display: none; }

