.map-layer {
  @include no-select;
  @include easing(300ms, opacity);

  color: $lightgray;
  font-family: 'Replica';
  font-size: 1.4rem;
  line-height: 1.4em;
  color: $black;

  &:not(:first-of-type) {
    margin-top: 0.75rem;
  }

  &[actionable=false][visible=false] {
    display: none;
  }

  &[actionable=false] {
    pointer-events: none;
  }

  &__label {}
  &__label input[type='checkbox'] { display: none; }
  &__label label {
    display: flex;
    cursor: pointer;
    img {
      margin-top: 0.1rem;
      margin-right: 0.4rem;
      height: 1.6rem;
      width: auto;
    }
  }

  &__label label &__title {
    display: inline-block;
    margin-left: $gutter;
  }

  &__label label &__title[data-suffix]:not([data-suffix='null'])::after {
    content: attr(data-suffix);
    display: block;
    font-size: 0.75em;
    text-transform: uppercase;
    margin-top: -5px;
  }

  &__label ~ *:not(:empty) {
    margin-top: 1rem;
  }

  &__legend, &__children {
    padding-left: 2rem + $gutter / 2;
  }


  &__children &__title {
    margin-left: $gutter / 2 !important;
  }

  &[disabled=true] { display: none; }
  &[visible=false] { opacity: 0.216; }
  &[visible=false] &__children,
  &[visible=false] &__legend {
    display: none;
  }
}

body[data-night=true] .map-layer__label label img {
  filter: invert(100%)
}
