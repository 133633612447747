.map {
  @include no-select;

  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;

  @media print { display: none; }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  &.is-ready &__container {
    @include easing(300ms, opacity);
    opacity: 1;
  }

  &__control {
    @include reset-button;

    z-index: 900;
    position: absolute;
    background-color: $white;
    border: 1px solid $black;
    font-size: 1.4rem;
    line-height: 2.8rem;

    &:not(input) {
      cursor: pointer;
      width: 2.8rem;
      height: auto;
    }

    &:not(button) {
      padding: 0 1ch;
    }

    svg {
      height: 1em;
      width: auto;
      vertical-align: middle;
      margin-bottom: 3px;
      fill: $black;
    }
  }

  &__control--code {
    top: 1rem + (2.8rem * 2) + 0.5rem;
    left: 1rem;

    &.has-copied::after {
      content: 'copié\00a0!';
      position: absolute;
      top: -1px;
      left: 2.8rem;
      margin-left: -2px;
      padding: 0 1ch;
      text-align: left;
      background-color: inherit;
      border: 1px solid $black;
      border-left-style: dashed;
    }
  }

  &__control--expand {
    top: 1rem + (2.8rem * 2) + 0.5rem;
    left: 1rem;
  }

  &.is-fullscreen &__control--expand {
    transform: scale(-1, 1);
  }

  &__control--center {
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    max-width: 50vw;
  }

  &__marker--text-only {
    pointer-events: none;

    width: 30rem !important;
    white-space: normal !important;
    border: 0 !important;

    font-family: 'Replica';
    font-size: 1.4rem;
    text-align: center;
    background-color: transparent;
    padding: 0;
    text-shadow:
      -1px -1px 0 rgba($white, 0.3),
      1px -1px 0 rgba($white, 0.3),
      -1px 1px 0 rgba($white, 0.3),
      1px 1px 0 rgba($white, 0.3);
  }

  &__container[data-zoom='4'] &__marker--text-only {
    color: red;
  }
}

.map-layers ~ .map .map__container {
  right: $menu__width--small;
}

.leaflet {
  &-container {
    background-color: $white--map;
  }

  &-tile--debug {
    outline: 1px solid cyan;
    &::after {
      position: absolute;
      content: attr(data-z)' / ['attr(data-x)' ; 'attr(data-y)']';
      background-color: cyan;
      font-family: monospace;
      font-size: 10px;
      top: 0;
      left: 0;
      padding: 0.5em;
    }
  }

  &-layer,
  &-marker-pane img {
    @include easing(300ms, opacity);
  }

  &-control-zoom,
  &-control-zoom * {
    font-family: 'Replica';
    font-weight: normal;
    border-radius: 0 !important;
    background-color: $white !important;
    color: $black !important;
  }

  &-control-zoom &-disabled {
    color: $lightgray !important;
  }

  &-bar {
    box-shadow: none !important;
    border: 0 !important;
  }

  &-touch &-bar a {
    width: 2.6rem;
    height: 2.6rem;
    line-height: 2.6rem;
    font-size: 1.8rem;
  }

  &-control {
    box-shadow: none;
    border: 1px solid $black !important;
  }

  &-zoom-box {
    border: 1px dashed $black;
    // 🤯 backdrop-filter: invert(100%);
  }

  &-tooltip {
    opacity: 1 !important;
    border-radius: 0;
    font-family: 'Replica';
    color: $black;
    box-shadow: none;
    border: 1px solid $black;
    &::before { display: none; }
  }

  &-invisible-icon {
    display: none;
  }

  &-touch-drag {
    touch-action: none !important;
  }
}
