.menu-entry {
  @include no-select;
  @include easing(300ms);

  position: relative;

  color: $lightgray;
  font-family: 'Replica';
  font-size: 1.4rem;
  line-height: 1.4em;
  margin-top: 0.75rem;

  &:hover { color: $gray; }
  &.is-open,
  &.is-active { color: $black; }

  &__prefix {
    font-feature-settings: 'tnum';
    font-variant-numeric: tabular-nums;
    font-family: 'Replica';
    text-align: right;
    float: left;
    line-height: 1;
    margin-top: 2px;
    text-align: center;
    min-width: $gutter;
  }

  &__title {
    @include easing(300ms, opacity);
    margin-left: $gutter * 2;
  }
}
