html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*::before,
*::after { box-sizing: inherit; }

body {
  min-height: 100%;
  margin: 0;
  padding: 0;

  font-family: 'Replica', Arial, sans-serif;
  font-size: 2rem;
  line-height: 1.6em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  background-color: $white;
  color: $black;
}

::selection {
  background: $lightgray;
  color: $black;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom; // Suppress the space beneath the baseline

  &.has-photoswipe {
    cursor: pointer;
    cursor: zoom-in;
  }
}

img[data-lazyload][data-loaded='true']:not(.medium-zoom-image--opened) {
  opacity: 0;
  animation: fade-in 1000ms cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

.medium-zoom-image,
.medium-zoom-overlay { z-index: 999; }

a {
  @include easing(300ms, border-color);
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid;
  padding-bottom: 1px;
  &:hover, &:focus {
    border-color: $lightgray;
  }
}

a:not([href]),
a[role="button"] {
  &, &:hover, &:focus {
    @include no-select;
    text-decoration: none;
    border: 0;
  }
}

abbr {
  @include easing(300ms, border-color);
  cursor: help;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px dashed;
  &:hover, &:focus {
    border-color: $lightgray;
  }
}
