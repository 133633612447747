.article {
  font-family: 'Tiempos';

  max-width: 600px;
  margin: 0 auto;
  padding:
    0
    $gutter
    $article__vertical-spacing
    $menu__width--small
  ;

  font-size: 1.8rem;
  line-height: 1.4em;

  @media print {
    a:not[role='button'] {
      border: 0;
       &::after {
        content: ' 'attr(href);
        color: $gray;
        font-family: 'Replica';
        font-style: italic;
        font-weight: normal;
        font-size: smaller;
      }
    }

    abbr { border: 0; }
  }

  @include mobile {
    width: 100vw;
    min-height: 100vh;
    padding-right: $gutter;
    font-size: 1.4rem;
    h1 { font-size: 2.8rem; }
    h2 { font-size: 2.2rem; }
    h3 { font-size: 1.6rem; }
  }

  &__header {
    position: relative;
  }

  &__nav {
    @include easing(300ms, color);

    color: $lightgray;
    font-family: 'Replica';
    font-size: 1.4rem;
    line-height: 1.4em;
    margin-top: 0.75rem;

    &:hover { color: $gray; }

    &::before, &::after {
      @include easing(300ms, transform);
      display: inline-block;
    }

    &--previous::before { content: '←\00a0'; }
    &--previous:hover::before { transform: translateX(-$gutter / 2); }
    &--next::after { content: '\00a0→'; }
    &--next:hover::after { transform: translateX($gutter / 2); }
  }

  &__header &__nav--previous {
    position: absolute;
    top: $gutter / 2;
    float: left;
    margin-left: -$gutter;
    @include mobile { margin-left: 0; }
  }

  .title {}
  .article {}

  h1 {
    font-size: 3.6rem;
    line-height: 1.1;
    font-weight: normal;
  }

  h2 {
    font-size: 2.8rem;
    line-height: 1.2;
    font-weight: normal;
    margin-top: 3em;
  }

  h3 {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 2em;
    line-height: 1.3;
  }

  &__header + h2 {
    margin-top: 0;
  }

  h1, h2, h3, h4, h5, h6,
  p,
  ul,
  ol:not(.flickity-page-dots),
  figure:not(.in-flickity),
  li,
  blockquote,
  cite {
    margin-bottom: 1em;
  }

  blockquote {
    margin: 2em #{-$gutter};

    line-height: 1.6em;
    font-style: italic;
    color: $gray;

    &::before {
      content: '«';
      margin-right: 0.5ch;
    }

    &::after {
      content: '»';
      margin-left: 0.5ch;
    }

    @include mobile {
      margin: 2em 0;
    }
  }

  cite {
    display: block;
    margin: -1em 0 2em 25%;
    transform: translateX($gutter);
    width: 75%;

    color: $gray;
    font-size: 1.4rem;
    line-height: 1.4em;
    font-style: normal;
    text-align: right;

    &::before {
      content: '—';
      margin-right: 0.25em;
    }
  }

  figure img {
    @include no-select;
  }

  figure:not(.in-flickity) {
    margin: 2em #{-$gutter};
  }

  figure.vignette:not(.in-flickity) {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  ol:not(.flickity-page-dots), ul {
    counter-reset: ol;
    list-style: none;

    li {
      position: relative;
      padding-left: $gutter;
    }

    li::before {
      position: absolute;
      left: 0;
    }
  }

  ul li::before { content: '\2013'; }
  ol:not(.flickity-page-dots) li::before {
    content: counter(ol) '.';
    font-size: smaller;
    counter-increment: ol;
    margin-top: 1px;
  }

  figcaption, .slideshow__caption {
    margin-top: 1em;
    padding: 0 $gutter;

    color: $gray;
    font-size: 1.4rem;
    line-height: 1.4em;
    text-align: center;
  }

  sup {
    position: relative;
    font-size: 1rem;
    line-height: 0;
    top: -0.5em;
    vertical-align: baseline;
  }

  // Prevent user abusing multiple line breaks for spacing
  h1 br,
  h2 br,
  h3 br,
  h4 br,
  h5 br,
  h6 br,
  br + br,
  p:empty,
  * > br:first-child:not(:last-child),
  * > br:last-child:not(:first-child) {
    display: none;
  }

  // Allow whatever line breaks inside a blockquote
  blockquote br {
    display: unset !important;
  }

  &__footer {
    margin-top: $article__vertical-spacing;
  }

  &__footer &__nav--next {
    float: right;
    margin-right: -$gutter;
    @include mobile { margin-right: 0; }
  }
}
