.map-layers {
  @include invisible-scroll(y);
  @include easing($menu__speed, transform, true);
  z-index: 1;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  padding: $article__vertical-spacing $gutter $gutter * 2;
  width: $menu__width--large;

  background-color: $white;
  border-left: 1px solid $black;

  transform: translate3d($menu__width--large - $menu__width--small, 0, 0);

  flex-direction: column;
  display: flex;

  @media print { display: none; }

  &:hover {
    transform: translate3d(0, 0, 0);
  }

  body.has-fullscreen-map & {
    transition: none;
    transform: none;
  }

  [data-type="tiles"] + [data-type="markers"] {
    margin-top: $gutter;
  }

  &__entries {
    margin-top: 0;
    flex: 1;
    list-style: none;

    // Offset the first level of map-layer__children and map-layer__legend so
    // that they are correctly hidden when the map-layers component is not hover
    & > .map-layer > .map-layer__children,
    & > .map-layer > .map-layer__legend {
      margin-left: $gutter / 2;
    }
  }

  .map-attribution {
    margin-top: $gutter * 2;
    padding-left: $gutter * 2;
  }
}


// SEE layout.scss for close button implementation
.map-layers-close-button { display: none; }
