.title {
  position: relative;
  line-height: 1.1;

  // In conjunction with &__anchor paddingTop, this allows offseting the
  // native jumps so that the margin is respected and the anchor is not too close
  // to the top of the viewport
  &.has-anchor {
    padding-top: $article__vertical-spacing;
  }

  &__anchor {
    @include easing(300ms);

    position: absolute;
    transform: translateX(-100%);

    top: 0;
    padding-top: $article__vertical-spacing;
    left: -$gutter;

    color: $lightgray;
    text-decoration: none;
    font-family: 'Replica';
    font-size: 1.4rem;
    line-height: 1;
    font-weight: bold;

    &::before {
      @include easing(300ms, opacity);
      content: '\00A7\00a0';
      font-size: small;
      color: $lightgray;
      vertical-align: 3px;
      opacity: 0;
    }

    @include mobile {
      display: none;
    }
  }

  &__anchor:hover { color: $gray; }
  &__anchor:hover::before { opacity: 1; }
}

// Move .title__content on the baseline of .title__anchor when their font-size
// are different
h1.title.has-anchor .title__content { margin-top: -1.4rem; }
h2.title.has-anchor .title__content { margin-top: -0.7rem; }
