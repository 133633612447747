.slideshow {
  margin: #{$gutter * 2} -$gutter;
  padding-top: $gutter;

  @include mobile {
    margin: #{$gutter * 2} 0;
  }

  figure {
    width: 100%;
    height: auto;

    figcaption { display: none; }
  }

  &__caption  {
    // SEE article.scss
  }

  .flickity {
    &-button { background: transparent !important; }

    &-prev-next-button {
      top: 0;
      width: $gutter;
      height: $gutter;
    }

    &-prev-next-button.previous {
      left: unset;
      top: -$gutter / 2;
      right: 0;
      transform: translate(-100%);
    }

    &-prev-next-button.next {
      top: -$gutter / 2;
      right: 0;
      transform: none;
    }

    &-button:disabled { display: none; }

    &-button-icon {
      @include easing(300ms, fill);
      fill: $lightgray;
    }

    &-button:hover &-button-icon { fill: $gray; }

    &-page-dots {
      position: absolute;
      top: -$gutter / 2;
      left: 0;
      display: flex;
      right: $gutter * 3;
      width: auto;
      margin-top: 0.5em;
      margin-bottom: 1.5em;
      bottom: unset;
      justify-content: flex-start;
    }

    &-page-dots .dot {
      @include easing(200ms);

      opacity: 1;
      height: 0;
      margin: 0;
      margin-top: -$gutter / 4;
      width: $gutter / 2;
      height: $gutter / 2;
      margin-right: 4px;
      border-radius: 100%;
      background: transparent;

      color: $lightgray;
      background: currentColor;

      &:last-child {
        margin-right: 0;
      }
    }

    &-page-dots .dot:hover { color: $gray; }
    &-page-dots .dot.is-selected { color: $black; }

    &-viewport {
      @include easing(300ms, height);
    }
  }
}

