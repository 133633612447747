// Main layout
main {
  display: flex;
  .article + .map {
    border-left: 1px solid $black;

    width: 50vw;
    max-width: calc(100vw - 800px);
    @media (min-width: #{960px * 2}) {
      width: calc(100vw - 960px);
    }
  }

  .map.is-fullscreen {
    position: fixed;
    left: $menu__width--small;
    right: $menu__width--large - $menu__width--small;
    width: unset;
    max-width: unset;
    border-left: 0;
  }
}

body.has-fullscreen-map {
  overflow: hidden;
}

// Push to the right when .menu opens
.menu:hover ~ .article
  { transform: translate3d($menu__width--large - $menu__width--small, 0, 0); }

// Push to the left when .map-layers opens
.map-layers:hover ~ .article + .map:not(.is-fullscreen)
  { transform: translate3d(-$menu__width--large + $menu__width--small, 0, 0); }

.menu ~ .article,
.menu ~ .map,
.map-layers ~ .article {
  @include easing(300ms, transform, true);
}

// Always show the map-layers panel on /carte on desktop
body[data-template='map'] {
  @include desktop {
    .map-layers { transform: none; }
    .map {
      width: calc(100vw - #{$menu__width--large - $menu__width--small});
    }
  }
}

// Improve panels UX on mobile
@include mobile {
  .menu, .map-layers {
    max-width: calc(100vw - 5rem);
  }

  .map-layers:hover ~ .map,
  .menu:hover ~ .article {
    pointer-events: none;
    &::after { opacity: 1; }
  }
  .map::after,
  .article::after {
    z-index: 9999;
    @include easing(300ms, opacity);
    pointer-events: none;
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 1;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
  }

  .map-layers-close-button,
  .menu-close-button {
    @include easing(300ms);
    display: block;
    z-index: 2;
    position: fixed;
    top: $gutter / 2;
    left: $menu__width--large + 1rem;
    right: 0;

    filter: invert(0) !important;

    opacity: 0;
    pointer-events: none;

    .map-layers:hover + &,
    .menu:hover + & {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .map-layers-close-button  {
    left: 0;
    right: $menu__width--large + 1rem;
  }
}

// Disable split-screen on mobile and tablet
@include devices (mobile, tablet) {
  .article + .map,
  body:not([data-template='map']) .map-layers { display: none; }

  .article {
    padding-left: $menu__width--small + $gutter;
    transform: none !important;
  }
}
