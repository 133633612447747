@font-face {
  font-family: 'Replica';
  font-display: auto;
  src: url('../fonts/replica-regular.woff2') format('woff2'),
       url('../fonts/replica-regular.woff') format('woff'),
       url('../fonts/replica-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Replica';
  font-display: auto;
  src: url('../fonts/replica-italic.woff2') format('woff2'),
       url('../fonts/replica-italic.woff') format('woff'),
       url('../fonts/replica-italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Replica';
  font-display: auto;
  src: url('../fonts/replica-bold.woff2') format('woff2'),
       url('../fonts/replica-bold.woff') format('woff'),
       url('../fonts/replica-bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Replica';
  font-display: auto;
  src: url('../fonts/replica-bold+italic.woff2') format('woff2'),
       url('../fonts/replica-bold+italic.woff') format('woff'),
       url('../fonts/replica-bold+italic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Tiempos';
  font-display: auto;
  src: url('../fonts/tiempos-text-web-regular.eot');
  src: url('../fonts/tiempos-text-web-regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/tiempos-text-web-regular.woff2') format('woff2'),
       url('../fonts/tiempos-text-web-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tiempos';
  font-display: auto;
  src: url('../fonts/tiempos-text-web-regular-italic.eot');
  src: url('../fonts/tiempos-text-web-regular-italic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/tiempos-text-web-regular-italic.woff2') format('woff2'),
       url('../fonts/tiempos-text-web-regular-italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Tiempos';
  font-display: auto;
  src: url('../fonts/tiempos-text-web-semibold.eot');
  src: url('../fonts/tiempos-text-web-semibold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/tiempos-text-web-semibold.woff2') format('woff2'),
       url('../fonts/tiempos-text-web-semibold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Tiempos';
  font-display: auto;
  src: url('../fonts/tiempos-text-web-semibold-italic.eot');
  src: url('../fonts/tiempos-text-web-semibold-italic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/tiempos-text-web-semibold-italic.woff2') format('woff2'),
       url('../fonts/tiempos-text-web-semibold-italic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}
