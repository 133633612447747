.map-layer-legend {
  @include no-select;

  margin-top: 0.25rem;
  display: flex;
  color: $gray;

  &__pattern {}
  &__pattern svg {
    height: 2rem;
    width: 2rem;
    line-height: 0;
  }

  &__label {
    margin-left: $gutter / 2;
  }

  &[disabled=true] {
    display: none;
  }
}
